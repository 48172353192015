import { Button, Popover, Position, ButtonGroup, Intent } from '@blueprintjs/core'
import React, { FC, useState, Fragment } from 'react'

import { history } from '../App'

interface Props {
  onUpdate?: (config: any) => void
}

export const Toolbar: FC<Props> = (props) => {
  return (
    <div style={{ marginBottom: 30 }}>
      <ButtonGroup fill large>
        <Button text="Menu" onClick={() => history.push('/')} />

        {props.children && (
          <Popover content={<div>{props.children}</div>} position={Position.BOTTOM}>
            <Button text="Configuration" />
          </Popover>
        )}
      </ButtonGroup>
    </div>
  )
}
