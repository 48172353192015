import axios from 'axios'

const createClient = (isAdmin: boolean, clientOptions: any) => {
  const client = axios.create({ timeout: 10000, ...clientOptions })

  return client
}

const baseURL = 'https://getdrunk.rocks'
//const baseURL = 'http://localhost:3005'

export default {
  getSecured() {
    return createClient(false, {
      headers: {
        Authorization: 'bearer lol'
      },
      baseURL
    })
  },

  getApi() {
    return createClient(true, { baseURL })
  }
}
