import { Popover, Button, Intent, Icon } from '@blueprintjs/core'
import { nanoid } from 'nanoid'
import React, { FC, useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Player } from '../'
import { BaseDialog, DialogBody, DialogFooter } from '../../../components/BaseDialog'
import IndividualPlayer from './IndividualPlayer'

interface Props {
  players: Player[]
  isOpen: boolean
  onClose: () => void
  onSave: (players: Player[]) => void
}

const PlayerList: FC<Props> = (props) => {
  const [players, setPlayers] = useState<Player[]>(props.players)

  useEffect(() => {
    setPlayers(props.players)
  }, [props.players, props.isOpen])

  useEffect(() => {
    if (!players?.length && props.isOpen) {
      add()
    }
  }, [props.isOpen])

  const add = () => {
    setPlayers([...players, { id: nanoid(), sex: 'f', name: '' }])
  }

  const save = () => {
    props.onSave(players)
    props.onClose()
  }

  const edit = (idx, { id, name, sex }: Player) => {
    const updatedPlayer = { id, name, sex }

    setPlayers((players) => {
      const newPlayers = [...players.slice(0, idx), updatedPlayer, ...players.slice(idx + 1)]
      return newPlayers
    })
  }

  const remove = (idx) => {
    const newPlayers = [...players.slice(0, idx), ...players.slice(idx + 1)]
    setPlayers(newPlayers)
  }

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) {
      return
    }

    const updatedList = [...players]
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1)
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem)
    setPlayers(updatedList)
  }

  return (
    <BaseDialog isOpen={props.isOpen} onClose={props.onClose}>
      <DialogBody>
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId="list-container">
            {(provided) => (
              <div className="list-container" {...provided.droppableProps} ref={provided.innerRef}>
                {players.map(({ id, sex, name }, idx) => (
                  <Draggable key={name} draggableId={id} index={idx}>
                    {(provided) => (
                      <div
                        className="item-container"
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                      >
                        <IndividualPlayer
                          player={{ id, sex, name }}
                          idx={idx}
                          remove={() => remove(idx)}
                          edit={(item) => edit(idx, item)}
                          insertPlayer={add}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Button text="Ajouter un joueur" icon="add" onClick={add} style={{ marginTop: 10 }} />
      </DialogBody>

      <DialogFooter>
        <Button onClick={props.onClose} intent={Intent.DANGER} text="Annuler" />
        <Button onClick={save} text="Enregistrer" />
      </DialogFooter>
    </BaseDialog>
  )
}

export default PlayerList
