import React, { FC } from 'react'

interface Props {
  suit?: string
  rank?: string
  isBack?: boolean
  onImageLoaded: () => void
}

export const suites = ['spades', 'hearts', 'diamonds', 'clubs']
export const ranks = ['ace', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'jack', 'queen', 'king']

const Card: FC<Props> = ({ suit, rank, isBack, onImageLoaded }) => {
  return isBack ? (
    <img src={`${process.env.PUBLIC_URL}/images/cards/back.svg`} onLoad={onImageLoaded} />
  ) : (
    <img src={`${process.env.PUBLIC_URL}/images/cards/${rank}_of_${suit}.svg`} onLoad={onImageLoaded} />
  )
}

export default Card
