import { Button, ButtonGroup } from '@blueprintjs/core'
import _ from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { BsGenderFemale, BsGenderMale } from 'react-icons/bs'
import { Player } from '..'
import { Question } from '../../../../../api/src/typings'

interface Props {
  players: Player[]
  entry?: Question
  onSelected?: (player: Player) => void
}

const PickRandomPlayer: FC<Props> = (props) => {
  const [player, setPlayer] = useState('')

  useEffect(() => {
    setPlayer('')
  }, [props.entry])

  const randomize = (sex) => {
    const playerList = sex === 'a' ? props.players : props.players.filter((x) => x.sex === sex)
    const randomized = _.random(0, playerList.length - 1)

    if (props.onSelected) {
      props.onSelected(playerList[randomized])
    } else {
      setPlayer(playerList[randomized]?.name)
    }
  }

  const isEnabled = props.players?.length > 2

  return (
    <div style={{ textAlign: 'center', marginTop: 21 }}>
      <div>
        <small>
          <b>Choisi un joueur au hasard</b>
        </small>
      </div>
      {isEnabled ? (
        <div style={{ marginTop: 3 }}>
          <ButtonGroup>
            <Button text="Femme" icon={<BsGenderFemale color="red" />} onClick={() => randomize('f')} />
            <Button text="Tous" onClick={() => randomize('a')} />
            <Button text="Homme" icon={<BsGenderMale color="blue" />} onClick={() => randomize('m')} />
          </ButtonGroup>
        </div>
      ) : (
        <small>
          Il faut plus de 2 joueurs pour utiliser cette fonction (ajoutez-en en cliquant sur le bouton Configuration en
          haut a droite)
        </small>
      )}
      {player && (
        <div style={{ marginTop: 10 }}>
          <b>{player}</b>
        </div>
      )}
    </div>
  )
}

export default PickRandomPlayer
