import { Button, Intent, ButtonGroup } from '@blueprintjs/core'
import React, { useState, FC, useEffect } from 'react'
import { GameProps, getGameState, logButtonPress, saveGameState, shuffleItems } from '..'
import { Question } from '../../../../api/src/typings'
import api from '../../api'
import { Rate } from '../../components/Rate'
import { Toolbar } from '../../components/Toolbar'

const allTags = [
  {
    label: 'Général',
    value: 'general'
  },
  {
    label: 'Couple',
    value: 'couple'
  }
]

interface GameState {
  currentIndex: number
  tags: string[]
}

const defaultState: GameState = {
  currentIndex: 0,
  tags: ['general', 'couple']
}

export const WhoIsMost: FC<GameProps> = (props) => {
  const [progress, setProgress] = useState(0)
  const [tags, setTags] = useState(['general', 'couple'])
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [questions, setQuestions] = useState<Question[]>([])
  const [allQuestions, setAllQuestions] = useState<Question[]>([])

  useEffect(() => {
    void loadQuestions()
  }, [])

  useEffect(() => {
    const filteredQuestions = allQuestions.filter((x) => x.tags?.find((t: any) => tags.includes(t)))

    setQuestions(shuffleItems(filteredQuestions))
    setCurrentIndex(progress)
  }, [tags, allQuestions])

  useEffect(() => {
    if (currentIndex > 0) {
      saveGameState<GameState>(props.gameId, { currentIndex, tags })
    }
  }, [tags, currentIndex, props.gameId])

  const loadQuestions = async () => {
    await api
      .getApi()
      .get(`questions/${props.gameId}`)
      .then(({ data }) => {
        const { currentIndex, tags } = getGameState<GameState>(props.gameId, defaultState)

        setProgress(currentIndex)
        setTags(tags)

        setAllQuestions(data)
      })
  }

  const toggleTag = (tag: string) => {
    if (!tags.includes(tag)) {
      setTags([...tags, tag])
    } else {
      setTags(tags.filter((x) => x !== tag))
    }
  }

  const next = () => {
    const isAtEnd = currentIndex + 1 > questions.length
    const nextIndex = isAtEnd ? 0 : currentIndex + 1

    setCurrentIndex(nextIndex)
    logButtonPress(props.gameId)
  }

  return (
    <div>
      <Toolbar>
        <div style={{ padding: 10 }}>
          <h5>Catégorie</h5>
          <ButtonGroup>
            {allTags.map((t) => (
              <Button
                text={t.label}
                key={t.label}
                active={tags.includes(t.value)}
                onClick={() => toggleTag(t.value)}
              ></Button>
            ))}
          </ButtonGroup>
        </div>
      </Toolbar>

      <Button text="Suivant" large onClick={next} fill intent={Intent.PRIMARY}></Button>

      {questions && (
        <h1>
          <div className="question">{questions[currentIndex]?.question}</div>
        </h1>
      )}

      <Rate
        question={questions[currentIndex]}
        gameId={props.gameId}
        currentIndex={currentIndex}
        questions={questions}
      />
    </div>
  )
}
