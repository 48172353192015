import { Button, Checkbox, FormGroup, TextArea } from '@blueprintjs/core'
import React, { useEffect, useState } from 'react'

export const InputBox = (props) => {
  const [single, setSingle] = useState(false)
  const [toParse, setToParse] = useState('')
  const [tags, setTags] = useState('')
  const [questions, setQuestions] = useState('')

  useEffect(() => {}, [toParse])

  const addQuestions = () => {
    const fixText = (item) => item.replace(/’/g, "'").replace(/[0-9]+. ?/g, '')
    const questionsList = questions
      .split(/\n/g)
      .filter((x) => x.trim().length)
      .map(fixText)

    console.info(questionsList)
  }

  return (
    <div>
      <FormGroup helperText="Parse existing questions">
        <TextArea fill value={toParse} onChange={(e) => setToParse(e.currentTarget.value)} rows={5} />
      </FormGroup>

      <FormGroup helperText="Available tags (one per line)">
        <TextArea fill value={tags} onChange={(e) => setTags(e.currentTarget.value)} rows={3} />
      </FormGroup>

      <Checkbox
        label="Single question"
        checked={single}
        onChange={(e) => setSingle(e.currentTarget.checked)}
      ></Checkbox>

      <div>
        <h5>Add questions</h5>
        <FormGroup helperText="Add questions (one per line)">
          <TextArea fill value={questions} onChange={(e) => setQuestions(e.currentTarget.value)} rows={5} />
        </FormGroup>
        <Button onClick={() => addQuestions()}></Button>
      </div>
    </div>
  )
}
