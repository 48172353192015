import { Button, Intent, ButtonGroup } from '@blueprintjs/core'
import React, { useState, FC, useEffect } from 'react'

interface Props {
  gameType: string
  onClick: (nextItem: 'truth' | 'dare' | 'random') => void
}

const ActionButtons: FC<Props> = ({ gameType, onClick }) => {
  const [disabled, setDisabled] = useState(false)

  const buttonClick = (item) => {
    setDisabled(true)
    setTimeout(() => {
      setDisabled(false)
    }, 2000)

    onClick(item)
  }
  if (gameType === 'random') {
    return (
      <ButtonGroup fill>
        <Button
          text="Suivant"
          onClick={() => buttonClick('random')}
          large
          fill
          intent={Intent.PRIMARY}
          disabled={disabled}
        />
      </ButtonGroup>
    )
  }

  return (
    <ButtonGroup fill>
      <Button
        text="Vérité"
        onClick={() => buttonClick('truth')}
        large
        fill
        intent={Intent.PRIMARY}
        disabled={disabled}
      />
      <div style={{ margin: 10 }}></div>
      <Button
        text="Conséquence"
        onClick={() => buttonClick('dare')}
        large
        fill
        intent={Intent.PRIMARY}
        disabled={disabled}
      />
    </ButtonGroup>
  )
}

export default ActionButtons
