const cards = [
  { rank: 'ace', suits: ['spades', 'clubs'], action: 'Bois 1 gorgée' },
  { rank: '2', suits: ['spades', 'clubs'], action: 'Bois 2 gorgée' },
  { rank: '3', suits: ['spades', 'clubs'], action: 'Bois 3 gorgée' },
  { rank: '4', suits: ['spades', 'clubs'], action: 'Bois 4 gorgée' },
  { rank: '5', suits: ['spades', 'clubs'], action: 'Bois 5 gorgée' },
  { rank: 'ace', suits: ['diamonds', 'hearts'], action: 'Distribue 1 gorgée' },
  { rank: '2', suits: ['diamonds', 'hearts'], action: 'Distribue 2 gorgée' },
  { rank: '3', suits: ['diamonds', 'hearts'], action: 'Distribue 3 gorgée' },
  { rank: '4', suits: ['diamonds', 'hearts'], action: 'Distribue 4 gorgée' },
  { rank: '5', suits: ['diamonds', 'hearts'], action: 'Distribue 5 gorgée' },
  {
    rank: '6',
    action: 'Rime / Dans ma valise',
    desc:
      'Tu dis un mot, ensuite les autres joueurs doivent trouver une rime chacun leur tour. Le premier joueur qui ne trouve pas de rime ou en réutilise une, boit une gorgée.'
  },
  {
    rank: '7',
    action: 'Fuck You',
    desc:
      'Tu dis « 1 », le joueur suivant doit continuer en disant « 2 », puis le suivant « 3 » etc. À chaque fois que le chiffre comporte un 7 (7, 17, 27) ou est multiple de 7 (7, 14, 21…), le joueur doit dire « fuck you » à la place du chiffre, puis on change de bord et le joueur précédent continue le compte. Le perdant boit une gorgée.'
  },
  {
    rank: '8',
    action: 'Thème / Catégorie',
    desc:
      'Tu dis un thème, par exemple les couleurs. Les joueurs doivent ensuite, chacun leur tour, dire toutes les couleurs énoncées précédemment, puis en ajouter une nouvelle. Exemple : Le jeu en est à : « rouge » « jaune » « bleu » : si vous voulez ajouter « violet » vous devrez dire : « rouge » « jaune » « bleu » « violet ». Le premier joueur qui se trompe devra boire une gorgée.'
  },
  {
    rank: '9',
    action: 'Règle inventée',
    desc:
      'Tu inventes une règle. Par exemple: vouvoiement obligatoire, ni oui ni non. Le joueur qui se trompe boit une gorgée.'
  },
  {
    rank: '10',
    action: 'Choisi ta pute',
    desc: 'Dès que tu prends une gorgée, la personne désignée doit aussi en prendre une avec toi.'
  },
  {
    rank: 'jack',
    action: 'Fuck You',
    desc:
      'Vous pouvez, à n’importe quel moment de la partie, crier « Fuck you ! », le dernier joueur vous imitant boit une gorgée.'
  },
  {
    rank: 'queen',
    action: 'Les filles boivent!',
    desc: 'Toutes les filles boivent! Celle qui pige la 4ème dame doit finir son verre!'
  },
  {
    rank: 'king',
    action: 'Les gars boivent!',
    desc: 'Tous les gars boivent! Celui qui pige le 4ème roi cale son verre!'
  }
]

export default cards
