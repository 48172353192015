import { Button, Intent } from '@blueprintjs/core'
import React, { useState, FC, useEffect } from 'react'
import { GameProps, getGameState, logButtonPress, saveGameState, shuffleItems } from '..'
import { Question } from '../../../../api/src/typings'
import api from '../../api'
import { Rate } from '../../components/Rate'
import { Toolbar } from '../../components/Toolbar'

interface GameState {
  currentIndex: number
}

const defaultState: GameState = {
  currentIndex: 0
}

export const MostLikely: FC<GameProps> = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [questions, setQuestions] = useState<Question[]>([])

  useEffect(() => {
    void loadQuestions()
  }, [props.gameId])

  useEffect(() => {
    if (currentIndex > 0) {
      saveGameState<GameState>(props.gameId, { currentIndex })
    }
  }, [props.gameId, currentIndex])

  const loadQuestions = async () => {
    const { data } = await api.getApi().get(`questions/${props.gameId}`)

    setQuestions(shuffleItems(data))

    const { currentIndex } = getGameState<GameState>(props.gameId, defaultState)
    setCurrentIndex(currentIndex)
  }

  const next = () => {
    const isAtEnd = currentIndex + 1 > questions.length
    setCurrentIndex(isAtEnd ? 0 : currentIndex + 1)
    logButtonPress(props.gameId)
  }

  return (
    <div>
      <Toolbar />
      <Button text="Suivant" large onClick={next} fill intent={Intent.PRIMARY} />

      {questions && (
        <h1>
          <div className="question">{questions[currentIndex]?.question}</div>
        </h1>
      )}

      <Rate
        question={questions[currentIndex]}
        gameId={props.gameId}
        currentIndex={currentIndex}
        questions={questions}
      />
    </div>
  )
}
