import { Popover, Button, ButtonGroup, FormGroup, TextArea, Intent, Tooltip } from '@blueprintjs/core'
import React, { useState } from 'react'
import { Question } from '../../../api/src/typings'
import api from '../api'
import { BaseDialog, DialogBody, DialogFooter } from './BaseDialog'
import { toastSuccess } from './Toaster'

interface Props {
  gameId: string
  question: Question
  currentIndex?: number
  questions?: Question[]
}

export const Rate = (props: Props) => {
  const [showSuggest, setShowSuggest] = useState(false)
  const [text, setText] = useState('')
  const [selected, setSelected] = useState<Question>()

  const positive = async () => {
    await api.getApi().get(`feedback/${props.question.id}/1`)
    toastSuccess('Avis positif enregistré!')
  }

  const negative = async () => {
    await api.getApi().get(`feedback/${props.question.id}/-1`)
    toastSuccess('Avis négatif enregistré!')
  }

  const suggest = () => {
    setSelected(props.question)
    setText(props.question.question)
    setShowSuggest(true)
  }

  const add = () => {
    setSelected(undefined)
    setText('')
    setShowSuggest(true)
  }

  const save = async () => {
    if (selected) {
      await api.getApi().post(`change/${selected?.id}`, { id: selected?.id, text })
      setShowSuggest(false)
    } else {
      await api.getApi().post(`suggest/${props.gameId}`, { text })
      setShowSuggest(false)
    }

    toastSuccess('Merci pour la suggestion !')
  }

  const ShowProgress = () => {
    if (!props.questions || props.currentIndex === undefined) {
      return <span style={{ marginRight: 50 }}></span>
    }

    return (
      <small className="ratingProgress">
        ({props.currentIndex + 1}/{props.questions.length})
      </small>
    )
  }

  return (
    <div className="rating">
      <BaseDialog isOpen={showSuggest} onClose={() => setShowSuggest(false)}>
        <DialogBody>
          <FormGroup label={selected ? 'Proposer une nouvelle formulation' : 'Suggérer une nouvelle question'}>
            <TextArea fill value={text} onChange={(e) => setText(e.currentTarget.value)} rows={5} />
          </FormGroup>
        </DialogBody>

        <DialogFooter>
          <Button onClick={() => setShowSuggest(false)} intent={Intent.DANGER} text="Annuler" />
          <Button onClick={save} text="Envoyer" />
        </DialogFooter>
      </BaseDialog>
      <Tooltip content="Bonne question">
        <Button icon="thumbs-up" onClick={positive} className="bottomButtons" intent={Intent.SUCCESS} />
      </Tooltip>
      <Tooltip content="Mauvaise question">
        <Button icon="thumbs-down" onClick={negative} className="bottomButtons" intent={Intent.DANGER} />
      </Tooltip>
      <ShowProgress />
      <Tooltip content="Suggérer une modification">
        <Button icon="edit" onClick={suggest} className="bottomButtons " />
      </Tooltip>
      <Tooltip content="Proposer une nouvelle question">
        <Button icon="add" onClick={add} className="bottomButtons" />
      </Tooltip>
    </div>
  )
}
