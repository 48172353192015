import { nanoid } from 'nanoid'
import ReactGA from 'react-ga'
import shuffleSeed from 'shuffle-seed'
import { WouldYouRather } from './WouldYouRather'

export interface GameProps {
  gameId: string
}

export interface GameState<T> {
  seed: string
  state: T
}

export const getGameStateKey = (gameName: string) => `${gameName}_state`

export function getGameState<T>(gameName: string, defaultState: T): T {
  const gameState = localStorage.getItem(getGameStateKey(gameName))
  if (gameState) {
    try {
      return JSON.parse(gameState)
    } catch {}
  }

  return defaultState
}

export function saveGameState<T>(gameName: string, state: T) {
  try {
    const stringState = JSON.stringify(state)
    localStorage.setItem(getGameStateKey(gameName), stringState)
  } catch (err) {
    console.error('Couldnt save game state', err)
  }
}

export function shuffleItems<T>(items: T[]): T[] {
  let currentSeed = localStorage.getItem('gameSeed')

  if (!currentSeed) {
    currentSeed = nanoid()
    localStorage.setItem('gameSeed', currentSeed)
  }

  return shuffleSeed.shuffle(items, currentSeed)
}

export const logButtonPress = (gameName: string, buttonName: string = 'Next') => {
  try {
    ReactGA.event({
      category: gameName,
      action: `${buttonName} Button Pressed`
    })
  } catch (err) {
    console.error('cant log', err)
  }
}

export const resetGame = () => {
  localStorage.setItem('gameSeed', nanoid())
}

export * from './NeverHaveIEver'
export * from './WhoIsMost'
export * from './MostLikely'
export * from './WouldYouRather'
export * from './Dice'
export * from './DrunkDice'
export * from './FuckYou'
export * from './TruthDare'
