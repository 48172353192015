import React, { FC, useEffect, useState } from 'react'
import { Player } from '..'
import { Question } from '../../../../../api/src/typings'

import PickRandomPlayer from './PickRandomPlayer'
import Timer from './Timer'

const RenderDare: FC<{ entry: Question; players: Player[] }> = ({ entry, players }) => {
  if (!entry) {
    return null
  }

  const [randomPlayer, setRandomPlayer] = useState<Player>()

  useEffect(() => {
    setRandomPlayer(undefined)
  }, [entry])

  const getTimer = () => {
    if (entry.tags?.find((x) => x.startsWith('timer'))) {
      const timer = entry.tags.find((x) => x.startsWith('timer'))
      const delay = timer?.replace('timer_', '')
      return Number(delay)
    }
  }

  const isGroup = entry.tags?.find((x) => x === 'group')
  const timer = getTimer()
  const hasPicker = entry.tags?.find((x) => x === 'picker')

  const replaceName = () => {
    if (!randomPlayer) {
      return entry.question
    }

    const playerName = randomPlayer.name
    const replaced = entry.question
      .replace(/quelqu'un d'autre/i, playerName)
      .replace(/la personne de ton choix/i, `de ${playerName}`)
      .replace(/d'une personne de ton choix/i, `de ${playerName}`)
      .replace(/d'une personne/, `de ${playerName}`)
      .replace(/quelqu'un/i, playerName)
      .replace(/une personne/i, playerName)
      .replace(/la personne/i, playerName)
      .replace(/d'un joueur/i, `de ${playerName}`)

    return replaced
  }

  return (
    <div>
      <h1>
        {isGroup && <div className="questionGroup">Conséquence de groupe!</div>}
        <div className="question">{replaceName()}</div>
      </h1>

      {isGroup && <div className="questionGroupFooter">Tu dois jouer à nouveau</div>}

      {hasPicker && players.length > 0 && (
        <PickRandomPlayer players={players} entry={entry} onSelected={(player) => setRandomPlayer(player)} />
      )}
      {timer && <Timer startSeconds={timer} id={entry.id}></Timer>}
    </div>
  )
}

export default RenderDare
