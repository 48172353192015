import { Button, Intent, ButtonGroup, Radio, RadioGroup, NumericInput } from '@blueprintjs/core'
import React, { useState, FC, useEffect } from 'react'
import { GameProps, getGameState, logButtonPress, saveGameState, shuffleItems } from '..'
import { Question } from '../../../../api/src/typings'
import api from '../../api'
import { Rate } from '../../components/Rate'
import { Toolbar } from '../../components/Toolbar'
import ActionButtons from './components/ActionButtons'
import PickRandomPlayer from './components/PickRandomPlayer'
import PlayerList from './components/PlayerList'
import RenderDare from './components/RenderDare'

interface GameState {
  level: number
  dareIndex: number
  truthIndex: number
  players: Player[]
  nextItem?: any
  currentPlayerIndex: number
  maxPerLvl: number
  tags: string[]
  type: 'normal' | 'random'
}

const defaultState: GameState = {
  level: 1,
  dareIndex: -1,
  truthIndex: -1,
  currentPlayerIndex: -1,
  maxPerLvl: 30,
  nextItem: '',
  players: [],
  type: 'normal',
  tags: ['kinky']
}

export interface Player {
  id?: string
  sex: 'm' | 'f' | 'u'
  name: string
}

const levelTags = [
  { label: 'Préliminaires', value: 1 },
  { label: 'Excitation', value: 2 },
  { label: 'Extase', value: 3 }
]

const randomDare = [0.75, 0.85, 0.95]

const ruleExample = [
  { desc: "Lorsqu'on parle de la personne à sa gauche/sa droite, on réfère à la première personne du sexe opposé" },
  { desc: "Un joueur peut diviser un timer en 2 s'il s'occupe de 2 personnes en même temps" }
]

const mandatoryTags = ['truth', 'dare']

export const TruthDare: FC<GameProps> = (props) => {
  const [tags, setTags] = useState(['kinky'])
  const [dareIndex, setDareIndex] = useState(-1)
  const [truthIndex, setTruthIndex] = useState(-1)
  const [dares, setDares] = useState<Question[]>([])
  const [truths, setTruths] = useState<Question[]>([])
  const [allQuestions, setAllQuestions] = useState<Question[]>([])
  const [nextItem, setNextItem] = useState<string>('')
  const [type, setType] = useState<'normal' | 'random'>('normal')
  const [players, setPlayers] = useState<Player[]>([])
  const [level, setLevel] = useState<number>(1)
  const [maxPerLvl, setMaxPerLvl] = useState(30)
  const [currentPlayerIndex, setCurrentPlayerIndex] = useState<number>(-1)
  const [showPlayers, setShowPlayers] = useState(false)

  useEffect(() => {
    void loadQuestions()
  }, [])

  useEffect(() => {
    const filteredDares = allQuestions.filter((x) => x.tags?.includes('dare') && x.tags.includes(`lvl_${level}`))
    const filteredTruths = allQuestions.filter((x) => x.tags?.includes('truth'))

    setDares(shuffleItems(filteredDares).slice(0, maxPerLvl))
    setTruths(shuffleItems(filteredTruths))
  }, [tags, allQuestions, level, maxPerLvl])

  useEffect(() => {
    if (dareIndex > 0 || truthIndex > 0 || players.length) {
      saveGameState<GameState>(props.gameId, {
        tags,
        type,
        level,
        dareIndex,
        truthIndex,
        players,
        nextItem,
        currentPlayerIndex,
        maxPerLvl
      })
    }
  }, [tags, props.gameId, dareIndex, truthIndex, level, type, players, nextItem, maxPerLvl, currentPlayerIndex])

  const loadQuestions = async () => {
    await api
      .getApi()
      .get(`questions/${props.gameId}`)
      .then(({ data }) => {
        const { truthIndex, dareIndex, tags, type, level, players, nextItem, currentPlayerIndex, maxPerLvl } =
          getGameState<GameState>(props.gameId, defaultState)

        setTruthIndex(truthIndex === undefined ? -1 : truthIndex)
        setDareIndex(dareIndex === undefined ? -1 : dareIndex)
        setTags(tags)
        setType(type || 'normal')
        setLevel(level || 1)
        setPlayers(players || [])
        setNextItem(nextItem)
        setCurrentPlayerIndex(currentPlayerIndex)
        setMaxPerLvl(maxPerLvl)

        setAllQuestions(data)
      })
  }

  const goToNextPlayer = () => {
    let nextIdx = currentPlayerIndex + 1
    if (nextIdx >= players.length) {
      nextIdx = 0
    }

    setCurrentPlayerIndex(nextIdx)
  }

  const next = (truthOrDare: 'truth' | 'dare' | 'random', samePlayer?: boolean) => {
    const currentItem = nextItem === 'dare' ? dares[dareIndex] : truths[truthIndex]
    const isGroupAction = currentItem?.tags?.find((x) => x === 'group') ? true : false
    if (type === 'random' || truthOrDare === 'random') {
      truthOrDare = Math.random() < randomDare[level - 1] ? 'dare' : 'truth'
    }

    setNextItem(truthOrDare)

    // Only switch when game is started and current question is not a group question
    if ((truthIndex > -1 || dareIndex > -1) && !isGroupAction && !samePlayer) {
      goToNextPlayer()
    }

    if (truthOrDare === 'truth') {
      const isAtEnd = truthIndex + 1 > truths.length
      const nextIndex = isAtEnd ? 0 : truthIndex + 1
      setTruthIndex(nextIndex)
    } else if (truthOrDare === 'dare') {
      const isAtEnd = dareIndex + 1 >= dares.length

      if (isAtEnd) {
        setLevel(level !== 3 ? level + 1 : 1)
        setDareIndex(0)
      } else {
        setDareIndex(dareIndex + 1)
      }
    }

    logButtonPress(props.gameId)
  }

  const changeLevel = (newLevel) => {
    setLevel(newLevel)
    setDareIndex(-1)
  }

  const Status = (props) => {
    if (props.full) {
      return (
        <div style={{ textAlign: 'center', marginTop: 50 }}>
          <b>{levelTags.find((x) => x.value === level)?.label}</b>
          <br></br>
          Vérités: {truthIndex + 1}/{truths.length} - Conséquences: {dareIndex + 1}/{dares.length}
        </div>
      )
    }

    return (
      <div style={{ textAlign: 'center', marginTop: 0 }}>
        <b>
          <small>{levelTags.find((x) => x.value === level)?.label}</small>
        </b>
      </div>
    )
  }

  const ShowNotStarted = () => {
    const isStarted = truthIndex > -1 || dareIndex > -1
    if (isStarted) {
      return null
    }

    return (
      <div style={{ textAlign: 'center' }}>
        Avant de débuter la partie, vous pouvez inscrire le nom des participants pour déterminer qui sera la cible de
        certaines conséquences.
        <br />
        <br />
        Autrement, ce sera à vous de déterminer les victimes :-)
        <div style={{ marginTop: 20 }}>
          <Button text="Modifier la liste des joueurs" onClick={() => setShowPlayers(true)}></Button>
        </div>
        {players.length > 0 && (
          <PickRandomPlayer
            players={players}
            onSelected={(player) => {
              const idx = players.findIndex((x) => x.id === player.id)
              setCurrentPlayerIndex(idx)
            }}
          />
        )}
      </div>
    )
  }

  const playerCount = (sex) => players?.filter((x) => x.sex === sex).length
  const hasPlayer = currentPlayerIndex > -1

  return (
    <div>
      <Toolbar>
        <div style={{ padding: 10 }}>
          <h5>Niveau</h5>
          <ButtonGroup>
            {levelTags.map((t) => (
              <Button text={t.label} key={t.label} active={level === t.value} onClick={() => changeLevel(t.value)} />
            ))}
          </ButtonGroup>

          <h5>Nombre de conséquences par niveau</h5>
          <NumericInput value={maxPerLvl} onValueChange={setMaxPerLvl} />

          <h5>Type de partie</h5>
          <RadioGroup onChange={(e) => setType(e.currentTarget.value as any)} selectedValue={type}>
            <Radio value="normal" label="Normal" />
            <Radio value="random" label="Aléatoire" />
          </RadioGroup>
          <h5>
            Joueurs: {playerCount('f')}F et {playerCount('m')}H
          </h5>

          <Button text="Modifier la liste des joueurs" onClick={() => setShowPlayers(true)}></Button>
        </div>
      </Toolbar>

      <div style={{ margin: 20 }}>
        <ActionButtons gameType={type} onClick={(item) => next(item)} />
      </div>

      <ShowNotStarted />

      {hasPlayer && <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{players[currentPlayerIndex]?.name}</div>}

      {nextItem === 'truth' && (
        <div>
          <h1>
            <div className="question">{truths[truthIndex]?.question}</div>
          </h1>
          <Rate question={truths[truthIndex]} gameId={props.gameId} />
        </div>
      )}
      {nextItem === 'dare' && (
        <div>
          <RenderDare entry={dares[dareIndex]} players={players} />
          <br />
          {/* <Rate question={dares[dareIndex]} gameId={props.gameId} /> */}
        </div>
      )}

      <PlayerList
        players={players}
        isOpen={showPlayers}
        onClose={() => setShowPlayers(false)}
        onSave={(players) => setPlayers(players)}
      />

      <Status full />
      <div style={{ textAlign: 'center', marginTop: 45 }}>
        {hasPlayer && <Button text="Sauter la question" onClick={() => next('random', true)}></Button>}
      </div>
    </div>
  )
}
