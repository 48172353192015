import React from 'react'

export const activityDice = [
  {
    id: 'never',
    label: "Je n'ai jamais",
    desc: "Dis quelque chose que tu n'a jamais fait. Tous les joueurs qui l'ont fait doivent boire."
  },
  {
    id: 'story',
    label: 'Raconte une histoire',
    desc: 'Débute avec un seul mot, les joueurs suivants doivent répéter la phrase et ajouter un mot supplémentaire'
  },
  {
    id: 'rhyme',
    label: 'Rhyme Time',
    desc: 'Choisi un mot, tous les joueurs doivent dire un mot qui rime avec celui-ci'
  },
  {
    id: 'verite',
    label: 'Vérité ou conséquence',
    desc: (
      <span>
        Choisi un joueur avec qui tu veux jouer à vérité ou conséquence.
        <br />
        Tous les joueurs peuvent ensuite décider la meilleur vérité ou conséquence.
        <br />
        <br /> Si le joueur refuse, il doit consommer 2x le montant des dés
      </span>
    )
  },
  {
    id: 'likely',
    label: 'Most Likely',
    desc: (
      <span>
        Choisi quelque chose de drôle ou d'embarassant que tu crois que quelqu'un est susceptible d'avoir fait. <br />
        <br />
        Au décompte de 3, tous les joueurs pointent la personne qui est la plus susceptible de l'avoir fait. <br />
        <br />
        Bois pour chaque joueur qui te pointe
      </span>
    )
  },
  { id: 'all', label: 'Tout le monde boit', desc: 'Tout le monde boit ce qui est indiqué sur le dé de drink' }
]

export const actionDice = [
  { label: 'Bois 1 gorgée' },
  { label: 'Bois 2 gorgée' },
  { label: 'Bois 3 gorgée' },
  { label: 'Distribue 1 gorgée' },
  { label: 'Fini ton drink' },
  { label: 'Prend un shot!' }
]
