import { FormGroup, NumericInput, Button, ButtonGroup } from '@blueprintjs/core'
import React, { useState, FC, useRef } from 'react'

// @ts-ignore
import ReactDice from 'react-dice-complete'
import 'react-dice-complete/dist/react-dice-complete.css'
import { GameProps } from '..'
import { Toolbar } from '../../components/Toolbar'

export const Dice: FC<GameProps> = (props) => {
  const reactDice = useRef<any>(null)
  const [total, setTotal] = useState<number | undefined>(undefined)
  const [dices, setDices] = useState(2)

  const rollAll = () => {
    setTotal(undefined)
    reactDice.current.rollAll()
  }

  return (
    <div>
      <Toolbar>
        <FormGroup label="Nombre de dés">
          <ButtonGroup>
            <NumericInput value={dices} onValueChange={(value) => setDices(value)} />
            <Button icon="plus" onClick={() => setDices(dices + 1)} />
            <Button icon="minus" onClick={() => setDices(dices - 1)} />
          </ButtonGroup>
        </FormGroup>
      </Toolbar>

      <div style={{ textAlign: 'center' }}>
        <ReactDice
          numDice={dices}
          ref={reactDice}
          rollTime={1.5}
          rollDone={(total: number) => setTotal(total)}
          faceColor="white"
          dotColor="black"
          outline={true}
          outlineColor="black"
        />
      </div>

      <div style={{ textAlign: 'center', marginTop: 15 }}>
        <Button text="Brasser tous les dés" onClick={rollAll}></Button>
        {total !== undefined && (
          <div style={{ marginTop: 10 }}>
            Total: <b>{total}</b>
          </div>
        )}
      </div>
    </div>
  )
}
