import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import './index.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import App from './App'

ReactGA.initialize('UA-188590533-1')

ReactDOM.render(<App />, document.getElementById('root'))
