import { Button, FormGroup, Intent } from '@blueprintjs/core'
import { createBrowserHistory } from 'history'
import { nanoid } from 'nanoid'
import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { FaDice, FaUsers } from 'react-icons/fa'
import { GiCardRandom } from 'react-icons/gi'
import { HiUsers } from 'react-icons/hi'
import { SiExpertsexchange } from 'react-icons/si'
import { Route, Router, Switch } from 'react-router-dom'

import { InputBox } from './components/InputBox'
import {
  getGameStateKey,
  NeverHaveIEver,
  WhoIsMost,
  MostLikely,
  DrunkDice,
  Dice,
  FuckYou,
  WouldYouRather,
  TruthDare
} from './games'

export const history = createBrowserHistory({ basename: '/' })

history.listen(() => {
  const page = history.location.pathname
  ReactGA.set({ page })
  ReactGA.pageview(page)
})

export const gameList = [
  {
    label: "Je n'ai jamais ...",
    id: 'neverHaveIEver',
    icon: FaUsers,
    component: NeverHaveIEver
  },
  {
    label: 'Qui serait le plus susceptible de ...',
    id: 'mostLikely',
    icon: FaUsers,
    component: MostLikely
  },
  { label: 'Tu préfères ...', id: 'wouldYouRather', icon: HiUsers, component: WouldYouRather },
  {
    label: 'Qui de nous deux est le plus ...',
    id: 'whoIsMost',
    icon: HiUsers,
    component: WhoIsMost
  },
  { label: 'Vérité ou conséquence? (coquin)', id: 'truthDare', icon: SiExpertsexchange, component: TruthDare },
  { label: 'Fuck You!', id: 'fuckyou', icon: GiCardRandom, component: FuckYou },
  { label: 'Dés de boisson (Drunk Dices)', id: 'drunkdice', icon: FaDice, component: DrunkDice },
  { label: 'Dés', id: 'dice', icon: FaDice, component: Dice }

  // { label: 'Game ou pas game?', id: 'game' },
  // { label: 'Bad People', id: 'badPeople' },

  //{ label: 'Snake Eye', id: 'snake' }
]

const GameList = () => {
  const [showButton, setShowButton] = useState(false)

  useEffect(() => {
    const currentSeed = localStorage.getItem('gameSeed')
    if (currentSeed) {
      setShowButton(true)
    } else {
      resetGame()
    }
  }, [])

  const resetGame = () => {
    localStorage.setItem('gameSeed', nanoid())
    for (const { id } of gameList) {
      localStorage.removeItem(getGameStateKey(id))
    }

    setShowButton(false)
  }

  const setTitle = (newTitle?: string) => {
    document.title = `P'tite soirée tranquille :-)${newTitle ? ` - ${newTitle}` : ''}`
  }

  return (
    <div style={{ margin: '10px' }}>
      {gameList.map((x) => (
        <Button
          text={x.label}
          key={x.label}
          large
          fill
          onClick={() => {
            setTitle(x.label)
            history.push(x.id)
          }}
          style={{ marginBottom: 5 }}
          icon={<x.icon></x.icon>}
        />
      ))}
      <br />
      {showButton && (
        <div style={{ textAlign: 'center' }}>
          <FormGroup helperText="Appuyez sur le bouton pour re-brasser les questions et les cartes pour repartir du début">
            <Button text="Effacer la partie courante" small fill intent={Intent.DANGER} onClick={resetGame} />
          </FormGroup>
        </div>
      )}
      <div className="createdBy">
        Créé par <a href="mailto:yann.allard1@gmail.com">Yann Allard</a>
      </div>
    </div>
  )
}

const App = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" render={() => <GameList />} />
        <Route exact path="/input" render={() => <InputBox />} />
        {gameList.map(({ component: Component, id }) => (
          <Route key={id} exact path={`/${id}`} render={() => <Component gameId={id} />} />
        ))}
      </Switch>
    </Router>
  )
}

export default App
