import _ from 'lodash'
import React, { useState, FC, useEffect } from 'react'

import { GameProps, getGameState, saveGameState, shuffleItems } from '..'
import { Toolbar } from '../../components/Toolbar'
import Card, { suites, ranks } from './Card'
import rules from './rules'

interface GameState {
  index: number
}

const defaultState: GameState = {
  index: -1
}

export const FuckYou: FC<GameProps> = (props) => {
  const [deck, setDeck] = useState<any[]>([])
  const [index, setIndex] = useState(-1)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    shuffleCards()
  }, [])

  const shuffleCards = () => {
    const cardCombination = _.flatMap(suites, (s) => ranks.map((r) => ({ suit: s, rank: r })))

    const { index } = getGameState<GameState>(props.gameId, defaultState)
    setDeck(shuffleItems(cardCombination))
    setIndex(index)
  }

  useEffect(() => {
    saveGameState<GameState>(props.gameId, { index })
  }, [index])

  const nextCard = () => {
    setLoaded(false)
    setIndex(index + 1)
  }

  const rule = rules.find(
    (r) => r.rank === deck?.[index]?.rank && (!r.suits || (r.suits || []).includes(deck?.[index]?.suit))
  )

  const onImageLoaded = () => setLoaded(true)

  return (
    <div>
      <Toolbar />

      <div style={{ textAlign: 'center' }}>
        <div onClick={nextCard}>
          {index === -1 && <Card isBack onImageLoaded={onImageLoaded}></Card>}
          {index > -1 && <Card suit={deck[index].suit} rank={deck[index].rank} onImageLoaded={onImageLoaded}></Card>}
        </div>
        <small>{index + 1} / 52</small>
        {loaded && (
          <React.Fragment>
            <h4>{rule?.action}</h4>
            {rule?.desc}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}
