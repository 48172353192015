import { Button, InputGroup, Icon, ControlGroup } from '@blueprintjs/core'
import _ from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { BsGenderFemale, BsGenderMale } from 'react-icons/bs'
import { Player } from '..'

interface Props {
  idx?: number
  edit: (props: Player) => void
  remove: () => void
  player: Player
  insertPlayer: () => void
}

const IndividualPlayer: FC<Props> = (props) => {
  const [sex, setSex] = useState(props.player.sex || 'f')
  const [name, setName] = useState(props.player.name)

  useEffect(() => {
    update()
  }, [sex])

  const icon = sex === 'm' ? <BsGenderMale color="blue" /> : <BsGenderFemale color="red" />

  const update = () => {
    // console.log('UPDATE', { idx: props.idx, id: props.player.id, sex, name })
    props.edit({ id: props.player.id, sex, name })
  }

  return (
    <ControlGroup>
      <Button icon="remove" onClick={props.remove} style={{ marginRight: 5 }} tabIndex={-1} />
      <InputGroup
        value={name}
        autoFocus
        onChange={(e) => setName(_.capitalize(e.currentTarget.value))}
        // onKeyDown={(e) => {
        //   if (e.key === 'Enter') {
        //     props.insertPlayer()
        //   }
        // }}
        style={{ width: 140 }}
        onBlur={() => {
          if (name.length) {
            update()
          }
        }}
      />
      <Button
        onClick={() => setSex(sex === 'm' ? 'f' : 'm')}
        icon={<Icon icon={icon} />}
        style={{ marginLeft: 5 }}
        tabIndex={-1}
      />
      <Button icon="drag-handle-horizontal" minimal style={{ marginRight: 5 }} tabIndex={-1} />
    </ControlGroup>
  )
}

export default IndividualPlayer
