import { Button, Intent } from '@blueprintjs/core'
import React, { useState, useEffect, FC } from 'react'
import moans from './moans.mp3'

interface Props {
  startSeconds: number
  id: number
}

const Timer: FC<Props> = (props) => {
  const [seconds, setSeconds] = useState(props.startSeconds)
  const [isActive, setIsActive] = useState(false)

  const audio = new Audio(moans)

  const toggle = () => setIsActive(!isActive)

  const reset = () => {
    setSeconds(props.startSeconds)
    setIsActive(false)
  }

  useEffect(() => {
    let interval: any = null

    if (isActive) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1)
      }, 1000)

      if (seconds === 0) {
        void audio.play()
      }
    } else if (!isActive || seconds === 0) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [isActive, seconds, props.id])

  const button = !isActive ? 'Démarrer le décompte' : 'Mettre sur pause'

  return (
    <div style={{ textAlign: 'center' }}>
      <div>
        <div style={{ fontWeight: 'bold', fontSize: '3em', margin: '15px' }}>
          {seconds > 0 ? `${seconds}` : 'Terminé!'}
        </div>
      </div>

      <div>
        <Button text={button} large onClick={toggle} intent={Intent.PRIMARY}></Button>
        <Button text="Reset" large onClick={reset}></Button>
      </div>
    </div>
  )
}

export default Timer
