import { Button, Intent, Radio, ButtonGroup, RadioGroup } from '@blueprintjs/core'
import React, { useState, FC, useEffect } from 'react'
import { GameProps, getGameState, logButtonPress, saveGameState, shuffleItems } from '..'
import { Question } from '../../../../api/src/typings'
import api from '../../api'
import { Rate } from '../../components/Rate'
import { Toolbar } from '../../components/Toolbar'

const allTags = [
  {
    label: 'Général',
    value: 'general'
  },
  {
    label: 'Soft',
    value: 'soft'
  },
  {
    label: 'Hot',
    value: 'hot'
  }
]

interface GameState {
  currentIndex: number
  tags: string[]
  type: string
}

const defaultState: GameState = {
  currentIndex: 0,
  type: 'group',
  tags: ['general', 'soft', 'hot']
}

export const NeverHaveIEver: FC<GameProps> = (props) => {
  const [progress, setProgress] = useState(0)
  const [type, setType] = useState('group')
  const [tags, setTags] = useState(['general', 'soft', 'hot'])
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [questions, setQuestions] = useState<Question[]>([])
  const [allQuestions, setAllQuestions] = useState<Question[]>([])

  useEffect(() => {
    void loadQuestions()
  }, [])

  useEffect(() => {
    const filteredQuestions = allQuestions.filter(
      (x) => x.tags?.find((t: any) => tags.includes(t)) && x.tags?.includes(type)
    )

    setQuestions(shuffleItems(filteredQuestions))
    setCurrentIndex(progress)
  }, [type, tags, allQuestions])

  useEffect(() => {
    if (currentIndex > 0) {
      saveGameState<GameState>(props.gameId, { currentIndex, type, tags })
    }
  }, [type, tags, currentIndex])

  const loadQuestions = async () => {
    const { data } = await api.getApi().get(`questions/${props.gameId}`)

    if (data) {
      const { currentIndex, type, tags } = getGameState<GameState>(props.gameId, defaultState)

      setProgress(currentIndex)
      setType(type)
      setTags(tags)

      setAllQuestions(data)
    }
  }

  const toggleTag = (tag: string) => {
    if (!tags.includes(tag)) {
      setTags([...tags, tag])
    } else {
      setTags(tags.filter((x) => x !== tag))
    }
  }

  const next = () => {
    const isAtEnd = currentIndex + 1 > questions.length
    const nextIndex = isAtEnd ? 0 : currentIndex + 1

    setCurrentIndex(nextIndex)
    logButtonPress(props.gameId)
  }

  return (
    <div>
      <Toolbar>
        <div style={{ padding: 10 }}>
          <h5>Catégorie</h5>
          <ButtonGroup>
            {allTags.map((t) => (
              <Button
                text={t.label}
                key={t.label}
                active={tags.includes(t.value)}
                onClick={() => toggleTag(t.value)}
              ></Button>
            ))}
          </ButtonGroup>

          <h5>Type de partie</h5>
          <RadioGroup onChange={(e) => setType(e.currentTarget.value)} selectedValue={type}>
            <Radio value="group" label="Groupe" />
            <Radio value="duo" label="Duo" />
          </RadioGroup>
        </div>
      </Toolbar>

      <Button text="Suivant" large onClick={next} fill={true} intent={Intent.PRIMARY}></Button>

      {questions && (
        <h1>
          <div className="question">{questions[currentIndex]?.question}</div>
        </h1>
      )}

      <Rate
        question={questions[currentIndex]}
        gameId={props.gameId}
        currentIndex={currentIndex}
        questions={questions}
      />
    </div>
  )
}
