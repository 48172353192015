import { Button, Intent, Checkbox, Spinner } from '@blueprintjs/core'
import _ from 'lodash'
import React, { useState, FC, useEffect } from 'react'
import { GameProps } from '..'
import { Toolbar } from '../../components/Toolbar'
import { activityDice, actionDice } from './dices'

export const DrunkDice: FC<GameProps> = (props) => {
  const [activity, setActivity] = useState(-1)
  const [action, setAction] = useState(-1)
  const [activities, setActivities] = useState<string[]>([])
  const [isRolling, setRolling] = useState(false)

  useEffect(() => {
    setActivities(activityDice.map((x) => x.id))
  }, [])

  const rollDice = () => {
    setRolling(true)

    setTimeout(() => {
      setActivity(_.random(0, activities.length - 1))
      setAction(_.random(0, 5))
      setRolling(false)
    }, 500)
  }

  const toggleActivity = (checked: boolean, activity: string) => {
    if (checked) {
      setActivities([...activities, activity])
    } else {
      setActivities(activities.filter((x) => x !== activity))
    }
  }

  const filteredActivities = activityDice.filter((x) => activities.includes(x.id))
  const validChoice = action !== -1 && activity !== -1 && activity < filteredActivities.length

  return (
    <div>
      <Toolbar>
        <div style={{ padding: 10 }}>
          {activityDice.map((x) => (
            <Checkbox
              label={x.label}
              value={x.id}
              checked={activities.includes(x.id)}
              onChange={(e) => toggleActivity(e.currentTarget.checked, x.id)}
            />
          ))}
        </div>
      </Toolbar>
      <Button text="Brasser les dés" large onClick={rollDice} fill={true} intent={Intent.PRIMARY} />

      {isRolling && (
        <div style={{ padding: 50 }}>
          <Spinner></Spinner>
        </div>
      )}
      {!isRolling && validChoice && (
        <div style={{ textAlign: 'center' }}>
          <h3>
            <div>{filteredActivities[activity].label}</div>
          </h3>
          <div>{filteredActivities[activity].desc}</div>
          <br />
          <h1>{actionDice[activity].label}</h1>
        </div>
      )}
    </div>
  )
}
